import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayouts from '../layouts/BaseLayouts'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  redirect: "/home",
  meta: {
    title: "首页"
  },
  component: BaseLayouts,
  children: [{
    path: "/home",
    meta: {
      title: "首页",
    },
    component: () => import("../views/Home"),
    },
    {
    path: "/publish",
    name:'publish',
    meta:{
        title: "发布"
    },
    component: () => import("../views/Home/publish")
    }
]
}, 
{
    path: "/product-detail/:name",
    name:'product-detail',
    meta:{
        title: "详情页"
    },
    component: () => import("../views/Products/index")
},
{
  path: '/privacy',
  name: 'Privacy',
  meta: {
    title: "隐私权政策"
  },
  component: () => import("../views/Privacy"),
},
{
    path:'/login',
    name:'Login',
    meta:{
        title:'登录'
    },
    component:()=> import("../views/Login/login")
},
{
    path:'/register',
    name:'Register',
    meta:{
        title:'注册'
    },
    component:()=> import("../views/Login/register")
}
]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};


router.afterEach(() => {
    window.scrollTo(0, 0)
  })

export default router