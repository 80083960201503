<template>
  <div>
    <el-footer
      height="auto"
      class="bottom"
    >
      <div class="bottom-header">
        <div class="bottom-list">
          <ul>
            <li>数据产品</li>
            <li
              v-for="(item,index) in productsList"
              :key="index"
              class="chosed-li"
              @click="handlerJump(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="bottom-list">
          <ul>
            <li>数据应用</li>
            <li
              v-for="(item,index) in applicationList"
              :key="index"
              class="chosed-li"
              @click="handlerJump(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="bottom-list">
          <ul>
            <li>数据运营</li>
            <li
              v-for="(item,index) in operationList"
              :key="index"
              class="chosed-li"
              @click="handlerJump(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="bottom-list">
          <ul>
            <li
              v-for="(item,index) in addressList"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <!-- <div class="QRcode">
            <img src="../../assets/image/home/image32.png"
                 alt="">
            <div class="icons-box">
              <a v-for="(item,index) in iconList"
                 :key="index"
                 ref="iconsRef"
                 class="icons"
                 target="_blank"
                 :style="`background: url(${item.iconHover}) no-repeat; background-size: 100% 100%;`"
                 :href="item.url" />
            </div>
          </div> -->
      </div>
      <div class="bottom-footer">
        <p
          class="link"
          @click="jumpToPrivacy"
        >
          法律声明及隐私权政策
        </p>
        <p>© 2016-2019 StartDT.com 版权所有 ICP证：<a href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备17004958号</a> </p>
        <p
          class="link"
          @click="jumpToGov"
        >
          <img
            class="hatImg"
            src="@/assets/image/beian.png"
          >浙公网安备 33011002015629号
        </p>
      </div>
    </el-footer>
  </div>
</template>

<script>
export default {
    data(){
    return{
      productsList: [
        {
          url: require('@/assets/image/home/Simba.png'),
          desc: '一站式大数据智能服务平台。',
          name: 'dataSimba',
          price: '面议',
          contacts: '汤雅琴',
          phone: '13671906270',
          qq: '',
          wechat:'13671906270',
          introduction: '一站式大数据智能服务平台，旨在为企业提供全链路的产品+技术+方法论服务。助力企业快速搭建安全、易用的数据中台，最大化释放价值，驱动业务增长与创新。'
        },
        {
          url: require('@/assets/image/home/Nuza.png'),
          desc: '一站式消费者运营平台。',
          name: 'dataNuza',
          price: '免费试用',
          contacts: '梁雅云',
          phone:'18666662432',
          qq:'652821847',
          introduction: '一站式消费者运营平台，凝练大数据和AI算法能力，为企业提供全链路的消费者运营服务， 助力企业实现客群运营、精准营销，为顾客生命周期提频增值，为企业降低营销成本、提升转化率。'
        },
        {
          url: require('@/assets/image/home/Maleon.png'),
          desc: '帮助企业自主搭建专业的数据可视化应用。',
          name: 'dataMaleon',
          price: '免费试用',
          contacts: '骆晓广',
          phone:'18658895983',
          qq:'40214230',
          introduction: '一款帮助企业自主搭建专业的数据可视化应用，实现生动的数据呈现、实时监控、智慧决策、数据分析等功能，简单高效、炫酷生动，满足多元场景展示需求，用视觉语言展现数据美学。'
        },
        {
          url: require('@/assets/image/home/Hubble.png'),
          desc: '企业数据门户，旨在为企业提供统一的报表平台、指标中心。',
          name: 'dataHubble',
          price: '面议',
          contacts: '骆晓广',
          phone:'18658895983',
          qq:'40214230',
          introduction:'企业数据门户，旨在为企业提供统一的报表平台、指标中心。作为人人可用的业务数据搜索引擎，用户可零代码便捷取数，有效帮助洞察数据、使用数据，为业务提升价值。'
        }],
      applicationList: [
          {
            url: require('@/assets/image/home/Cockpit.jpg'),
            desc: '数据赋能企业管理，经营动态随时掌握',
            name: '智能管理驾驶舱',
            price: '免费体验',
            contacts: '郑为进',
            phone:'18805148558',
            qq:'',
            wechat:'18805148558',
             introduction:'智能管理驾驶舱，为管理者提供数据决策支持的一站式管理信息中心系统。从管理者的决策环境、企业管理综合指标的定义以及信息的表述，提供详尽的指标体系，实时反映企业运行状态。帮助企业沉淀数据资产，深度挖掘数据价值，用数据赋能经营管理，为商业决策提供数据支持。'
         
          },
          {
            url: require('@/assets/image/home/Replenishment.jpg'),
            desc: '实现从人工经验式补货向数据化分析补货的转型升级',
            name: '智能补货',
            price: '免费体验',
            contacts: '郑为进',
            phone:'18805148558',
            qq:'',
            wechat:'18805148558',
            introduction:'智能补货系统，借助数据中台、数据智能模型及算法应用能力，以历史积累的商品销售数据及智能销量预测模型为基础，为用户提供智能化、便捷化、可视化的人工智能补货策略服务，实现商品补货从人工经验式分析向数据化分析的转型升级。'
          },
          {
            url: require('@/assets/image/home/Nissin.jpg'),
            desc: '数据赋能企业管理，经营动态随时掌握',
            name: '智能日清',
            price: '免费体验',
            contacts: '郑为进',
            phone:'18805148558',
            qq:'',
            wechat:'18805148558',
            introduction:'智能日清，依托数据中台及AI算法能力，通过算法模型，综合多维度因素，分析缺货、断货及促销因素，在销量预测的基础上，提出销售和补货的策略，帮助生鲜企业、零售商超等实现日清智能化，提升售罄率，降低缺货率和损耗率，切实提升毛利。'
          },
          {
            url: require('@/assets/image/home/HumanEffect.jpg'),
            desc: '数据赋能企业管理，经营动态随时掌握',
            name: '智能人效',
            price: '免费体验',
            contacts: '郑为进',
            phone:'18805148558',
            qq:'',
            wechat:'18805148558',
            introduction:'智能人效管理系统，基于大数据和AI算法技术，实现智能定编、智能排班、人效智能诊断、员工能力画像、同区域人力统筹、离职优秀员工返聘和敏捷兼职等应用能力，为企业提供一站式人效运营服务，切实提升零售企业门店人效，降低用人成本。'
          },
          {
            url: require('@/assets/image/home/Conjuncture.jpg'),
            desc: '商情信息，一手掌握',
            name: '智能商情',
            price: '面议',
            contacts: '郑为进',
            phone:'18805148558',
            qq:'',
            wechat:'18805148558',
            introduction:'智能商情系统，依托大数据和AI能力，聚合多源数据，助力企业实现行业数据的多维度、多指标比对分析，全方位洞察品类结构，实时获取一手商情，掌握流行趋势，为商品企划、订补铺、销售运营等环节提供建议，提高商品ROI。'
          },
          {
            url: require('@/assets/image/home/Recommend.jpg'),
            desc: '千人千面，精准触达',
            name: '智能推荐',
            price: '面议',
            contacts: '朱文凯',
            phone:'13237002887',
            qq:'2388514418',
            introduction:'依托数据中台整合用户、交易、商品、商家等数据，基于数据算法模型，建立用户画像，对商品推荐进行预测，实现千人千面的精准推荐，有效提升转化效果。'
          },
        ],
      operationList:  [
          {
            url: require('@/assets/image/home/Operate.jpg'),
            desc: '以消费者为中心',
            name: '消费者运营服务',
            price: '免费试用',
            contacts: '梁雅云',
            phone:'18666662432',
            qq:'652821847',
            introduction:'消费者运营服务旨在帮助企业建立 「从消费者视角驱动销售增长」的经营逻辑和运营体系产出可落地、直接应用于业务的解决方案让消费者 （会员）运营脱虚向实，直接分解并带动销售与利润增长。'
          }
        ],
    addressList: [
    {
        name: '联系我们'
    },
    {
        name: '电话：0571-28121848'
    },
    {
        name: '邮箱：business@startdt.com'
    },
    {
        name: '地址：杭州市余杭区仓前街道仓兴街1号23幢'
    },
    {
        name: '时间：09:00—18:00（工作日）'
    },
    ],
    }
    },
    methods:{
        // handlerJump (item) {
        //     if (item.link === '#') return
        //     window.open(item.link, '_blank')
        //     console.log(item)
        // },
        handlerJump (item) {
        this.$router.push({name:'product-detail', params:{name: item.name}})
        localStorage.setItem('productDetail',JSON.stringify(item))
    },
         jumpToPrivacy () {
            let routeUrl = this.$router.resolve({
                path: "/privacy"
            });
            window.open(routeUrl.href, '_blank');
        },
        jumpToGov () {
            window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002015629', '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
a:link, a:visited, a:active {
    color: #ffffff;
    text-decoration: none;
}
ul {
  list-style: none;
  padding: 0;
  li {
    padding: 0 20px 5px;
  }
}
.bottom {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #20293c;
  color: #fff;
  padding: 30px 0 15px;
  &-header {
    display: flex;
    width: 100%;
    justify-content: center;
    .bottom-list {
      margin: 0 20px;
      .chosed-li {
        transition: color 300ms ease-in-out;
        cursor: pointer;
        &:hover {
          color: #1ccada;
        }
      }
    }
    .icons-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 10px;
      .icons {
        display: inline-block;
        width: 44px;
        height: 44px;
      }
    }
  }
  &-footer {
    p {
      text-align: center;
    }
    .link {
      cursor: pointer;
    }
    .hatImg {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}
</style>