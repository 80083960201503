const state = {
  searchVal: ''
}

const mutations = {
  saveSearchVal(state, val) {
    state.searchVal = val
    console.log(state.searchVal)
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}